import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import VueScrollTo from 'vue-scrollto'
import user from '@/store/user'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  hashbang: true,
  linkActiveClass: 'active',
  mode: 'hash',
  base: __dirname,
  routes
})



const guard = (to, from, next) => {
  // const loggedIn = user.state.loggedIn
  // const roles = user.state.user.roles
  // // FIXME: virer cette exception (ainsi que le side menu du mainLayout ainsi que les boutons de la page admin/vendors)
  // const vendorsViews = ['Vendors admin', 'Vendors view', 'Auth log in', 'Auh forgot password', 'Auth reset password', 'Auth', 'Account']
  // if (!to.meta.auth) return next()
  // if (to.meta.auth && loggedIn) {
  //   if (roles.find(role => role.alias === 'super-admin')) return next()
  //   if (roles.find(role => role.alias === 'vendors-viewer')) {
  //     if (vendorsViews.includes(to.name)) {
  //       return next()
  //     }
  //     return next('/admin/vendors')
  //   }
  //   if (to.path.includes('admin')) {
  //     return next('/')
  //   }
  //   // force publisher-fp
  //   if (roles.find(role => role.alias === 'publisher-fp') && to.path === '/') {
  //     return next('/trackers')
  //   }

  //   next()
  // } else {
  //   if (to.path !== '/auth/login') {
  //     user.state.redirectAfterLogin = to.fullPath
  //   }
  //   return next({path: '/auth/login'})
  // }
  // next()
  // Always redirect to login page
  if (to.path === '/auth/login') {
    return next()
  }
  return next('/auth/login')
}

router.beforeEach((to, from, next) => {
  store.state.options.isColumnFilterOpen = false
  store.state.options.isFilterPanelOpen = false
  store.dispatch('user/getCurrentUser')
    .then(resp => {
      VueScrollTo.scrollTo('#app', 300)
      guard(to, from, next)
    })
})

export default router
